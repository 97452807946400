import React from "react";

function TrustedBy() {
    return (
        <div className="TrustedBy">
            <h3>Trusted by many happy customers, including</h3>
            <img src="https://i.ibb.co/mNJJcyD/Group-7.png" alt="Trusted By" />
        </div>
    );
}

export default TrustedBy