import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";

import GameBanner from "./Sub-Components/GameBanner";

function GameInfo() {

    const { id } = useParams();
    const [game, setGame] = useState({});
    const [loading, setLoading] = useState(true);
    const [themeColor, setThemeColor] = useState("");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${API_BASE_URL}/core/get-game/?id=${id}`)
        .then((res) => res.text())
        .then((text) => {
            try {
                const data = JSON.parse(text);
                setGame(data.data[0]);
                setLoading(false);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                console.error("Response text:", text);
            }
        })
        .catch((err) => {
            console.error("Fetch error:", err);
        });
    }, [id]);

    // this is a temporary solution, will be replaced when the new backend is ready
    useEffect(() => {
        if (game.game_type) {
          switch (game.game_type) {
            case "CityQuest":
              setThemeColor("rgb(131, 184, 14)");
              break;
            case "HomeQuest":
              setThemeColor("rgb(255, 180, 0)");
              break;
            case "OfficeQuest":
              setThemeColor("rgb(2, 120, 255)");
              break;
            default:
              setThemeColor("");
          }
        }
      }, [game]);
    // --------------------------------------------
        
    if (loading) {
        return <div>Loading...</div>;
    }

    const bookingLink = `https://book.ardi.one/en?type=${game.game_type.toLowerCase()}&id=${id}`

    const imageLink = `${MEDIA}/game-images/${game.image.split('/').pop()}`

    // console.log(game)

    return (
        <div className="GameInfo">

            <GameBanner game={game} bookingLink={bookingLink} imageLink={imageLink} themeColor={themeColor}/>

            <div className="GameInfoSeeAll">
                <div className="GameInfoSeeAllContent">
                    <a href={`/games/${game.game_type}`}>
                        <div className="GameInfoSeeAllText">See All Games<div style={{fontSize: "26px", fontWeight: "300"}} >&nbsp;&nbsp;→</div></div>
                        <hr></hr>
                    </a>
                </div>
            </div>

            <div style={{backgroundImage: `url(${imageLink})`}} className="GameInfoImageDiv">
                <div className="GameInfoImageDivContentOuter">
                    <div style={{backgroundColor: themeColor}} className="GameInfoImageDivContentInner">
                        <h1>What is {game.title}?</h1>
                        <p>{game.description}</p>
                    </div>
                    <button onClick={() => navigate(`/faq/${game.game_type.toLowerCase()}`)} style={{backgroundColor: themeColor}} className="GameInfoButton">FAQ</button>
                </div>
            </div>

            <div style={{backgroundColor: themeColor}} className="GameInfoSteps">
                <div className="GameInfoStepsUpper">
                    <h1>Game Info</h1>
                    <hr/>
                </div>
                <div className="GameInfoStepsLower">
                    <div className="GameInfoStepsLowerItem">
                        <img src={`${MEDIA}/game-page-icons/area.svg`} alt={game.name}/>
                        <h3>Area</h3>
                        <h4>{game.area ? game.area : "-"}</h4>
                    </div>
                    <div className="GameInfoStepsLowerItem">
                        <img src={`${MEDIA}/game-page-icons/difficulty.svg`} alt={game.name}/>
                        <h3>Difficulty</h3>
                        <h4>{game.difficulty ? `${game.difficulty} / 10` : "-"}</h4>
                    </div>
                    <div className="GameInfoStepsLowerItem">
                        <img src={`${MEDIA}/game-page-icons/gamelength.svg`} alt={game.name}/>
                        <h3>Game Length</h3>
                        <h4>{game.duration ? `${game.duration} min` : "-"}</h4>
                    </div>
                    <div className="GameInfoStepsLowerItem">
                        <img src={`${MEDIA}/game-page-icons/players.svg`} alt={game.name}/>
                        <h3>Number of Players</h3>
                        <h4>{game.min_players_per_team} - {game.max_players_per_team} per team</h4>
                    </div>
                    <div className="GameInfoStepsLowerItem">
                        <img src={`${MEDIA}/game-page-icons/language.svg`} alt={game.name}/>
                        <h3>Language</h3>
                        <h4>{game.language}</h4>
                    </div>
                </div>
            </div>

            <div className="GameInfoOverview">

                <div className="GameInfoOverviewItem">
                    <img src={"https://i.ibb.co/Vx13sYV/Game-info-step-icon.png"} alt={game.name}/>
                    <div className="GameInfoOverviewItemText">
                        <h1>Team Up</h1>
                        <p>
                        Gather Your Team:<br/><br/>
                        Recruit 2-5 participants who are ready for an espionage adventure.<br/><br/>
                        Coordinate with friends, family, or colleagues and pick a date that suits everyone.<br/><br/>
                        </p>
                    </div>
                </div>

                <div className="GameInfoOverviewItem">
                    <img src={"https://i.ibb.co/Vx13sYV/Game-info-step-icon.png"} alt={game.name}/>
                    <div className="GameInfoOverviewItemText">
                        <h1>Make a booking </h1>
                        <p>
                        Gather Your Team:<br/><br/>
                        Recruit 2-5 participants who are ready for an espionage adventure.<br/><br/>
                        Coordinate with friends, family, or colleagues and pick a date that suits everyone.<br/><br/>
                        </p>
                    </div>
                </div>

                <div className="GameInfoOverviewItem">
                    <img src={"https://i.ibb.co/Vx13sYV/Game-info-step-icon.png"} alt={game.name}/>
                    <div className="GameInfoOverviewItemText">
                        <h1>Ready to go?</h1>
                        <p>
                        Gather Your Team:<br/><br/>
                        Recruit 2-5 participants who are ready for an espionage adventure.<br/><br/>
                        Coordinate with friends, family, or colleagues and pick a date that suits everyone.<br/><br/>
                        </p>
                    </div>
                </div>

                <div className="GameInfoOverviewItem">
                    <img src={"https://i.ibb.co/Vx13sYV/Game-info-step-icon.png"} alt={game.name}/>
                    <div className="GameInfoOverviewItemText">
                        <h1>Investigate</h1>
                        <p>
                        Gather Your Team:<br/><br/>
                        Recruit 2-5 participants who are ready for an espionage adventure.<br/><br/>
                        Coordinate with friends, family, or colleagues and pick a date that suits everyone.<br/><br/>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default GameInfo