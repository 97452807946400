import React, { useState, useEffect } from "react";
import QuestCard from "./QuestCard";
import {testQuests} from "../../Test-Data/quests";

function WhatKindOfQuester() {
    const [quests, setQuests] = useState([]);
    const [selectedTab, setSelectedTab] = useState("None");

    useEffect(() => {
        // fetch data from backend
        setQuests(testQuests);
    }, [])

    const sortOrders = {
        "Adventure": [0, 3, 1],
        "Home": [1, 4, 5],
        "Escape": [2, 0, 5],
        "None": [0, 1, 2, 3, 4, 5]
    }

    const handleTabClick = (e) => {
        if (selectedTab === e.target.id) {
            setSelectedTab("None");
        }
        else {
            setSelectedTab(e.target.id);
        }
    }

    const showQuests = (tab) => {
        if (tab === "None") {
            return quests.map((quest, index) => {
                return (
                    <QuestCard key={index} quest={quest} />
                )
            })
        } else {
            return quests.map((quest, index) => {
                if (sortOrders[tab].includes(index)) {
                    return (
                        <QuestCard key={index} quest={quest} />
                    )
                } else {
                    return null;
                }
            })
        }
    }

    // const getTabClass = (tab) => {
    //     return selectedTab === tab ? "WhatKindOfQuesterSortTabsSelected" : "";
    // };

    return (
        <div className="WhatKindOfQuesterContainer">
            <div className="WhatKindOfQuester" id="WhatKindOfQuester">
                <h1 style={{color: '#F6511D'}}>What Kind of</h1>
                <h1>Quester Are You?</h1>
            </div>

            <div className="WhatKindOfQuesterSortTabs">
                <button id="Adventure" onClick={handleTabClick}>
                    I Want To Go On An Adventure!
                </button>
                <button id="Home" onClick={handleTabClick}>
                    Solving Scary Mysteries! <br/>
                    From The Safety Of My Couch
                </button>
                <button id="Escape" onClick={handleTabClick}>
                    I Want To Share My Awesome Escape-Room<br/>Skills With All My Colleagues
                </button>
            </div>

            <div className="QuestCardContainer">
                {showQuests(selectedTab)}
            </div>
        </div>
    );
}
export default WhatKindOfQuester