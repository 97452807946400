import React from "react";
import { getFirstSentence } from "../../tools";
import { useNavigate } from "react-router-dom";

function TrendingCard({ game, index }) {

    let themeColor

    const navigate = useNavigate();

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL
    const imageLink = `${MEDIA}/game-images/${game.image.split('/').pop()}`

    switch (game.game_type) {
        case "CityQuest":
            themeColor = "#83B80E"
            break;
        case "HomeQuest":
            themeColor = "#FFB400"
            break;
        case "OfficeQuest":
            themeColor = "#0278FF"
            break;
        default:
            themeColor = "#f6511a"
    }

    return (
        <div style={{backgroundColor: themeColor}} className="TrendingCard">
            <div className="">
                <img className={`TrendingCardImage ${index % 2 === 0 ? "DiagonalClipA" : "DiagonalClipB"}`} 
                src={imageLink} />
            </div>
            <div className="TrendingCardLower">
                <h3>{game.title}</h3>
                <hr></hr>
                <p>{getFirstSentence(game.description)}</p>
                    <button onClick={() => navigate(`/games/gameinfo/${game.id}`)} style={{color: themeColor}}>Read More</button>
            </div>
        </div>
    );
}

export default TrendingCard