import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TrendingCard from "./TrendingCard";

function TrendingCarousel() {
    const [trendingGames, setTrendingGames] = useState([]);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const endpoint = `${API_BASE_URL}/gamescript/game/`;

    useEffect(() => {
      fetch(endpoint)

      .then((res) => res.text())
      .then((text) => {
          try {
              const data = JSON.parse(text);
              setTrendingGames(data);
              console.log(data);
          } catch (error) {
              console.error("Failed to parse JSON:", error);
              console.error("Response text:", text);
              setError(error);
          }
      })
      .catch((err) => {
          console.error("Fetch error:", err);
          setError(err);
      });
    }, [])

    const showTrendingGames = () => {
        return trendingGames.filter((game) => 
            game.language === "en" 
            && !game.title.includes("Delete") 
            && !game.title.includes("Cabin")
            && !game.title.includes("TSSD")
            && game.image

          ).map((game, index) => {
            return <TrendingCard game={game} index={index} key={game.id} />
        })
    }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
        {showTrendingGames()}
    </Slider>
  );
};

export default TrendingCarousel;
