import React from "react";


function ReviewCard({ review }) {

    const stars = "⭐".repeat(review.star);


    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const imageLink = `${MEDIA}/${review.icon.split('/').pop()}`

    return (
        <div className="ReviewCard">
            <div className="WhatCustomersSayLower">
                <img src={imageLink} alt="What Customers Say" />
                <div className="WhatCustomersSayLowerText">
                    <img src="https://i.ibb.co/3hxVhB7/Clipart-Key-2271265.png" alt="Quote" />
                    <h4>{review.title}</h4>
                    <p>{review.subtitle}
                        <br/>
                        <br/>
                        <strong>- {review.reviewer_name}</strong>
                        <br/>
                        {/* CEO Of Next World */}
                        {stars}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ReviewCard