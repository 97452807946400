import React, { useState, useEffect } from "react";

import {testNews} from "../Test-Data/news";
import NewsCard from "./Sub-Components/NewsCard";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function News() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetch(`${API_BASE_URL}/core/blog/`)
        .then((res) => res.text())
        .then((text) => {
            try {
                const data = JSON.parse(text);
                setNews(data);
                console.log(data);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                console.error("Response text:", text);
            }
        })
        .catch((err) => {
            console.error("Fetch error:", err);
        });

        setNews(testNews);
    }, []);

    const showNews = () => {
        return news.map((news, index) => {
            return <NewsCard number={index + 1} news={news} key={news.id} />
        })
    }

    const headLines = () => {
        return news.map((newsItem) => {
            return (
                <a style={{color: "#FFF", textDecoration: "none"}} key={newsItem.id} href={`#${newsItem.id}`}>
                    <h3>{newsItem.title}</h3>
                </a>
            );
        });
    };

    return (
        <div className="News">
            <div className="NewsSpacer">
                <div className="NewsSpacerLeft"/>
                <div className="NewsSpacerRight"/>
            </div>
            <div className="NewsBanner">
                <div className="NewsBannerLeft">
                    <div className="LatestPostsContainer">
                    <h1>Latest Posts</h1>
                        <div className="LatestPosts">
                            {news ? headLines() : null}
                        </div>
                    </div>
                </div>
                <div className="NewsBannerRight">
                    <h1>Blog and News</h1>
                </div>
            </div>

            <div className="NewsFilterButtons">
                <button>Filter</button>
                <button>Search</button>
                <button>Archives</button>
            </div>

            <div className="NewsCardContainer">
                {news ? showNews() : null}
            </div>
        </div>
    );
}

export default News