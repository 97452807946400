import React from "react";
import Banner from "./Sub-Components/Banner";
import WhatKindOfQuester from "./Sub-Components/WhatKindOfQuester";
import Trending from "./Sub-Components/Trending";
import WhyChooseUs from "./Sub-Components/WhyChooseUs";
import CheckUsOut from "./Sub-Components/CheckUsOut";
import TrustedBy from "./Sub-Components/TrustedBy";
import WhatCustomersSay from "./Sub-Components/WhatCustomersSay";
import ContactUs from "./Sub-Components/ContactUs";

function Home() {
    return (
        <div>
            <Banner />
            <WhatKindOfQuester />
            <Trending />
            <WhyChooseUs />
            <CheckUsOut />
            <TrustedBy />
            <WhatCustomersSay />
            <ContactUs color="#F6511A" />
        </div>
    );
}


export default Home