import React from "react";

import QuestBanner from "./Sub-Components/QuestBanner";
import WhatIsThisQuest from "./Sub-Components/WhatIsThisQuest";
import GameCardDisplay from "./Sub-Components/GameCardDisplay";
import WhatCustomersSay from "./Sub-Components/WhatCustomersSay";
import CheckUsOut from "./Sub-Components/CheckUsOut";
import ContactUs from "./Sub-Components/ContactUs";

import {testQuests} from "../Test-Data/quests";

function Games() {
    return (
        <div>
            <QuestBanner bannerColor="#ffb400" buttonColor="#F6511A"/>
            <WhatIsThisQuest quest={testQuests[1]}/>
            <GameCardDisplay />
            <WhatCustomersSay />
            <CheckUsOut />
            <ContactUs color="#ffb400" />
        </div>
    );
}

export default Games