import React from "react";

import FAQItem from "./FAQItem";

function FAQItemDisplay({faqs, categoryUUID}) {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

    const showFaqs = () => {
        if (faqs) {
            const filteredFaqs = faqs.filter(faq => faq.games_category.toString() === categoryUUID.toString());
            if (filteredFaqs.length > 0) {
                return filteredFaqs.map(faq => (
                    <FAQItem faq={faq} key={faq.category_faq_id} />
                ));
            }
        }
        return <div>No FAQs available for this category.</div>;
    };

    return (
        <div style={{backgroundImage: `url(${MEDIA}/patterns/large-white.svg)`}} className="FAQItemDisplay">
            {showFaqs()}
        </div>
    );
}

export default FAQItemDisplay