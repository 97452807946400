


export const testQuests = [
    {
        id: 1,
        name: "CityQuest",
        pageTitle: "CityQuest - The city is your playground!",
        pageSubtitle: "Exciting immersive detective games for private and corporate events!",
        description: "It is a new type of group activity in town that can be described as a mix of escape game, treasure hunt and geocaching.",
        long_description: "Everyone enjoys watching detective TV-series! How about becoming a part of it? Bring your family, friends or colleagues and solve the mystery whenever you want. Yes, you read that right, our activities are available 24/7. Play at night, Christmas Eve, Midsummer, in other words - whenever you have the time and the mood. Use our browser-based investigation app which has the starting material you need to begin the investigation, find new clues, solve riddles and unravel the mystery.",
        image: "https://ardi-default-eu.eu-central-1.linodeobjects.com/game-assets/game_category/3b66fa62-c0ac-4f76-95c2-9cc71309f41a/image/4c8cd32d-1440.jpeg",
        backgroundColor: "#f2f8e6",
        iconColor: "#83B80E",
        bookingLink: "https://book.ardi.one/en?type=cityquest",
        isAvailable: true
    },
    {
        id: 2,
        name: "HomeQuest",
        pageTitle: "Challenge your family and friends to an exciting and fun detective game",
        pageSubtitle: "Solve a mystery at home or online!",
        description: "A digital board game experience that will help to bring friends and family together. It can be easily played at home as well as online via a conference call.",
        long_description: "HomeQuest is a detective game that is perfect for a home evening with family and friends. You can play in one room or organize a video call. Either way, you will get an immersive experience and connect on a whole new level! Use our browser-based investigation app to analyze facts, collect clues, and solve puzzles while trying to unravel the mystery case together. By the way, the game is valid for 1 year so book now and play whenever you feel like it! Check out the video showing the new world where you and your team will do your first investigation!",
        image: "https://ardi-default-eu.eu-central-1.linodeobjects.com/game-assets/game_category/fdb73786-fbae-43fa-8089-34777446d527/image/homequest-gamesession-2_uid_61825792b55.jpg",
        backgroundColor: "#FFF7E6",
        iconColor: "#FFB400",
        videoLink: "https://www.youtube.com/embed/C9_3a2ID5SQ?si=hKR660zSfzJWst_4",
        bookingLink: "https://book.ardi.one/en?type=homequest",
        isAvailable: true

    },
    {
        id: 3,
        name: "OfficeQuest",
        pageTitle: "Exciting team building activity on site or online",
        pageSubtitle: "Create engagement among your colleagues and bring the entire team together. Quick booking and easy to get started.",
        description: "This game will help to improve your teamwork while solving puzzles together with your colleagues. You can play in the office or online via conference call.",
        long_description: "Every mystery will activate your logical thinking, collaborative skills and attention to detail. It's time to be creative if you want to solve the problem!",
        image: "https://www.figma.com/file/njZZtCimk2hWd9uH9mUT7v/image/3f44cae39132f417c80c8d104c7f5aae084014b5",
        backgroundColor: "#E6F1FF",
        iconColor: "#0278FF",
        videoLink: "https://www.youtube.com/embed/go4Bq890XNY?si=pCQTVuJjTmf5ApGo",
        bookingLink: "https://book.ardi.one/en?type=officequest",
        isAvailable: true
    },
    {
        id: 4,
        name: "TouristQuest",
        description: "It is a new type of group activity in town that can be described as a mix of escape game, treasure hunt and geocaching.",
        image: "https://images.unsplash.com/photo-1545668875-815b08584187?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        backgroundColor: "#FEEEE8",
        iconColor: "#F6511A",
        isAvailable: false
    },
    {
        id: 5,
        name: "SchoolQuest",
        description: "A digital board game experience that will help to bring friends and family together. It can be easily played at home as well as online via a conference call.",
        image: "https://ardi-default-eu.eu-central-1.linodeobjects.com/game-assets/game_category/085118c3-2a6d-436a-b0d6-6d2a0b071fd1/image/homequest-gamesession-3_uid_618257935e8.jpg",
        backgroundColor: "#F5E7F0",
        iconColor: "#9D0F6A",
        isAvailable: false
    },
    {
        id: 6,
        name: "PaperQuest",
        description: "This game will help to improve your teamwork while solving puzzles together with your colleagues. You can play in the office or online via conference call.",
        image: "https://images.unsplash.com/photo-1677295922463-147d7f2f718c?q=80&w=1128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        backgroundColor: "#E6F1FF",
        iconColor: "#B50EB8",
        isAvailable: false
    },
]
