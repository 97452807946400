import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FAQItemDisplay from "./Sub-Components/FAQItemDisplay";

function FAQ() {

    const { category } = useParams();
    const [categoryUUID, setCategoryUUID] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [themeColor, setThemeColor] = useState("");
    const [faqs, setFaqs] = useState([]);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // ##################################################################
    // this is a temporary solution, will be replaced when the new backend is ready.
    useEffect(() => {
        switch (category) {
            case "cityquest":
                setCategoryUUID("3b66fa62-c0ac-4f76-95c2-9cc71309f41a");
                setCategoryName("CityQuest");
                setThemeColor("rgb(131, 184, 14)");
                break;
            case "homequest":
                setCategoryUUID("fdb73786-fbae-43fa-8089-34777446d527");
                setCategoryName("HomeQuest");
                setThemeColor("rgb(255, 180, 0)");
                break;
            case "officequest":
                setCategoryUUID("d6962d38-51a0-4c25-a48c-e604a0cdc178");
                setCategoryName("OfficeQuest");
                setThemeColor("rgb(2, 120, 255)");
                break;
            default:
                setCategoryUUID("");
                break;
        }    
    }, [category]);
    // ##################################################################

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const res = await fetch(`${API_BASE_URL}/gamescategory/games-faq/`);
                const text = await res.text();
                try {
                    const data = JSON.parse(text);
                    setFaqs(data);
                } catch (error) {
                    console.error("Failed to parse JSON:", error);
                    console.error("Response text:", text);
                }
            } catch (err) {
                console.error("Fetch error:", err);
            } finally {
            }
        };
        if (categoryUUID) {
            fetchFaqs();
        }
    }, [API_BASE_URL, categoryUUID]);

    return (
        <div style={{backgroundColor: themeColor}} className="FAQ">
            <hr></hr>
            <h2>{categoryName}</h2>
            <h1>Frequently Asked Questions</h1>
            <FAQItemDisplay faqs={faqs} categoryUUID={categoryUUID} />
        </div>
    );
}

export default FAQ