import React, { useState, useEffect } from "react";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ReviewCard from "./ReviewCard";

function WhatCustomersSay() {
    const [error, setError] = useState(null);
    const [reviews, setReviews] = useState([]);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    
    const endpoint = `${API_BASE_URL}/gamescategory/games-reviews/`;

    useEffect(() => {
        fetch(endpoint)
  
        .then((res) => res.text())
        .then((text) => {
            try {
                const data = JSON.parse(text);
                setReviews(data);
                console.log(data);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                console.error("Response text:", text);
                setError(error);
            }
        })
        .catch((err) => {
            console.error("Fetch error:", err);
            setError(err);
        });
      }, [])


      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      };

      const showReviews = () => {
        return reviews.map((review, index) => {
            return <ReviewCard review={review} key={review.id} />
        })
    }

    return (
        <div className="WhatCustomersSay">
            <div className="WhatCustomersSayUpper">
                <h1>What Customers Say</h1>
            </div>
            <Slider {...settings}>
                {showReviews()}
            </Slider>
            
        </div>
    );
}

export default WhatCustomersSay