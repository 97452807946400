import React from "react";
import TrendingCarousel from "./TrendingCarousel";

function Trending() {

    return (
        <div className="Trending">
            <h4>Top Selling</h4>
            <h1>Trending</h1>
            <TrendingCarousel />
        </div>
    );
}

export default Trending