
export const gallery = [
    {
        id: 1,
        alt: "alt text",
        url: "https://ardi.one/cityquest/assets/images/3e6920f6-360.jpeg",
    },
    {
        id: 2,
        alt: "alt text",
        url: "https://ardi.one/homequest/assets/images/ea6d22c8-360.jpeg",
    },
    {
        id: 3,
        alt: "alt text",
        url: "https://ardi.one/cityquest/assets/images/ccf0c33a-360.jpeg",
    },
    {
        id: 4,
        alt: "alt text",
        url: "https://ardi.one/cityquest/assets/images/643daeca-360.jpeg",
    },
    {
        id: 5,
        alt: "alt text",
        url: "https://ardi.one/officequest/assets/images/df264963-360.jpeg",
    },
    {
        id: 6,
        alt: "alt text",
        url: "https://ardi.one/officequest/assets/images/37fecba7-360.jpeg",
    },
]