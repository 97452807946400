import React from "react";

function Banner() {
    return (
        <div className="banner">
            <div className="bannerImage"></div>
            <div className="bannerColorBlock"></div>
            
            <div className="bannerContent">
                <div className="bannerContentLeft">
                    <h1>Platform For<br/>Top-Class Online &<br/>Offline Immersive <br/>Adventure Games</h1>
                    <div className="bannerButtons">
                        <a href="https://book.ardi.one/en"><button>Book Now</button></a>
                        {/* <a href="/reviews"><button href="/reviews">Reviews</button></a> */}
                    </div>
                </div>
                <div className="bannerContentRight">
                    <img src="https://www.figma.com/file/njZZtCimk2hWd9uH9mUT7v/image/f91d2d65b61cf92ee3d88dadec1b9d26f2f17ff8" alt="Place Holder"/>
                </div>
            </div>
        </div>
    );
}

export default Banner