import React from "react";

function WhatIsThisQuest({quest}) {
    return (
        <div className="WhatIsThisQuest">

            <div className="WhatIsThisQuestLeft">
                <div style={{backgroundColor: quest.iconColor}} className="WhatIsThisQuestLeftColorBlock"></div>
                <img src={quest.image} alt="Place Holder"/>
            </div>

            <div className="WhatIsThisQuestRight">
                <h1>What is <br/>{quest.name}?</h1>
                <div style={{width: "100%"}}>
                    <hr/>
                </div>
                <p>{quest.long_description}</p>
                <div style={{width: "100%"}}>
                    {/* <button>FAQ</button> */}
                </div>
            </div>

        </div>
    );
}

export default WhatIsThisQuest