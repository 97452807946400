import React from "react";
import GameCard from "./GameCard";


function GameCardDisplay({games, color}) {

    const showGames = () => {
        return games.map((game) => {
            return <GameCard game={game} color={color} key={game.id} />
        })
    }
    return (
        <div className="GameCardDisplay">
            {showGames()}
        </div>
    );
}

export default GameCardDisplay