import React from "react";

function GameCard({ game, color }) {

  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL

  const bookingLink = `https://book.ardi.one/en?category=${game.game_script__game_type}&id=${game.game_script_id}`

  const imageLink = `${MEDIA}/game-images/${game.game_script__image.split('/').pop()}`
  const iconLink = `${MEDIA}/game-card-icons/${game.game_script__icon.split('/').pop()}`

  return (
    <div className="GameCard">
        <div style={{backgroundColor: color, backgroundImage: `url(${imageLink})`, border: `3px solid ${color}`}} className="GameCardImage">
        <div className="GameCardLocationContainer">
            {game.game_script__location ? <div className="GameCardLocation">{game.game_script__location}</div> : <div></div>}
            {game.game_script__icon ? <img style={{backgroundColor: color, borderRadius: "50%", padding: "10px"}} className="GameCardIcon" src={iconLink}/> : <div></div> }
        </div>
        <div className="GameCardTitle">{game.game_script__title}</div>
        {/* <div className="GameCardSubtitle">{game.game_script__episode > 0 ? `Number of episodes: ${game.episodes}` : `Single Game`}</div> */}
        <div className="GameCardSubtitle">{game.game_script__duration} minute - Game</div>

        </div>
      <div className="GameCardBody">
        <div className="GameCardDescription">{game.game_script__description}</div>
        <div className="GameCardAvailability">
          Available in: &nbsp;
          {game.game_script__language.includes('en') ? <span className="GameCardFlag">🇺🇸</span> : null}
          {game.game_script__language.includes('sw') ? <span className="GameCardFlag">🇸🇪</span> : null}
        </div>

        <div className="GameCardPrice">${game.price_per_unit} per user</div>

        <a href={`/games/gameinfo/${game.game_script_id}`}>
          <button style={{backgroundColor: color}} className="GameCardButton">Read More</button>
        </a>

        <a href={bookingLink}>
          <button style={{backgroundColor: color}} className="GameCardButton">Book Now</button>
        </a>

      </div>
    </div>
  );
}

export default GameCard;
