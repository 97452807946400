import React, { useState, useEffect } from "react";

import QuestBanner from "./Sub-Components/QuestBanner";
import WhatIsThisQuest from "./Sub-Components/WhatIsThisQuest";
// import GameInfo from "./Sub-Components/GameInfo";
// import WhatCustomersSay from "./Sub-Components/WhatCustomersSay";
import CheckUsOut from "./Sub-Components/CheckUsOut";
import ContactUs from "./Sub-Components/ContactUs";
import GameCardDisplay from "./Sub-Components/GameCardDisplay";

import {testQuests} from "../Test-Data/quests";
// import {gameInfo} from "../Test-Data/gameInfo";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function QuestType({type}) {
    const [games, setGames] = useState([]);
    const [error, setError] = useState(null);

    const endpoint = `${API_BASE_URL}/core/get-games-by-category/?category=${type}`;


    useEffect(() => {
        fetch(endpoint)

        .then((res) => res.text())
        .then((text) => {
            try {
                const data = JSON.parse(text);
                setGames(data);
                // console.log(data);
            } catch (error) {
                console.error("Failed to parse JSON:", error);
                console.error("Response text:", text);
                setError(error);
            }
        })
        .catch((err) => {
            console.error("Fetch error:", err);
            setError(err);
        });
    }, [type]);

    // this is a temporary solution, will be replaced when the new backend is ready
    let category
    if (type === "CityQuest") {
        category = 0
    } else if (type === "HomeQuest") {
        category = 1
    } else if (type === "OfficeQuest") {
        category = 2
    }
    // ------------------------------



    const color = testQuests[category].backgroundColor

    return (
        <div>
            <QuestBanner
                bannerColor={color}
                buttonColor={testQuests[category].iconColor}
                bookingLink={testQuests[category].bookingLink}
                videoLink={testQuests[category].videoLink}
                pageTitle={testQuests[category].pageTitle}
                pageSubtitle={testQuests[category].pageSubtitle}
            />
            <WhatIsThisQuest quest={testQuests[category]}/>
            <GameCardDisplay games={games} color={testQuests[category].iconColor} />
            {/* <WhatCustomersSay /> */}
            <CheckUsOut />
            <ContactUs color={testQuests[category].iconColor} />
        </div>
    );
}

export default QuestType