import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

// Components ----------------------------------------------------------------
import Navbar from './Components/Sub-Components/Navbar';
import Footer from './Components/Sub-Components/Footer';
import Home from './Components/Home';
import Games from './Components/Games';
import News from './Components/News';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import Creators from './Components/Creators';
import QuestType from './Components/QuestType';
import GameInfo from './Components/GameInfo';
import FAQ from './Components/FAQ';
// ---------------------------------------------------------------------------
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/games/cityquest" element={<QuestType type={"CityQuest"} />} />
        <Route path="/games/homequest" element={<QuestType type={"HomeQuest"} />} />
        <Route path="/games/officequest" element={<QuestType type={"OfficeQuest"} />} />
        {/* <Route path="/games/touristquest" element={<QuestType type={"TouristQuest"} />} /> */}
        {/* <Route path="/games/escapequest" element={<QuestType type={"EscapeQuest"} />} /> */}
        {/* <Route path="/games/paperquest" element={<QuestType type={"PaperQuest"} />} /> */}
        {/* <Route path="/games/puzzlequest" element={<QuestType type={"PuzzleQuest"} />} /> */}
        {/* <Route path="/games/schoolquest" element={<QuestType type={"SchoolQuest"} />} /> */}
        <Route path="/games/gameinfo/:id" element={<GameInfo  />} />
        <Route path="/faq/:category" element={<FAQ />} />
        <Route path="/news" element={<News />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/creators" element={<Creators />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
