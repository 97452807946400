import React, { useState } from "react";

function FAQItem({ faq }) {

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };


    return (
        <div onClick={toggleExpanded} className="FAQItem">
            <h3>{faq.question.toUpperCase()}</h3>
            <hr></hr>
            {expanded && <p>{faq.answer}</p>}
        </div>
    );
}

export default FAQItem

