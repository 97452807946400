import React from "react";

function GameBanner({game, bookingLink, themeColor}) {

    const buttonCSS = {
        backgroundColor: themeColor,
        color: "#FFF",
        border: "none",
    }
    const buttonHoverCSS = {
        backgroundColor: "#FFF",
        color: themeColor,
        border: `1px solid ${themeColor}`,
    }

    const bannerCSS = {
        backgroundColor: themeColor,
    }

    return (
        <div className="banner">
            <div className="bannerImage"></div>
            <div style={bannerCSS} className="bannerColorBlock"></div>
            
            <div className="bannerContent">
                <div className="bannerContentLeft">
                    <h1>{game.title}</h1>
                    {/* <p>{pageSubtitle}</p> */}

                    <div className="bannerButtons">

                        <a href={bookingLink}>
                            <button style={buttonCSS} onMouseOver={(e) => {
                                    Object.assign(e.target.style, buttonHoverCSS);
                                }} onMouseOut={(e) => {
                                    Object.assign(e.target.style, buttonCSS);
                                }}>
                                    Book Now
                            </button>
                        </a>

                        {/* <a href="/reviews">
                            <button style={buttonCSS} onMouseOver={(e) => {
                                    Object.assign(e.target.style, buttonHoverCSS);
                                }} onMouseOut={(e) => {
                                    Object.assign(e.target.style, buttonCSS);
                                }}>
                                    Reviews
                            </button>
                        </a> */}

                    </div>
                </div>
                <div className="VideoContainer">
                    <iframe 
                        width="560" 
                        height="315" 
                        src={game.video_url}
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerpolicy="strict-origin-when-cross-origin" 
                        allowfullscreen>
                    </iframe>
                </div>
                {/* <div className="bannerContentRight">
                    <img src="https://www.figma.com/file/njZZtCimk2hWd9uH9mUT7v/image/d491e88904c6655307d3dd0158a305777df38c42" alt="Place Holder"/>
                </div> */}
            </div>
        </div>
    );
}

export default GameBanner