import React from "react";
import { ardiIcon } from "../../tools";

function QuestCard({ quest }) {
    
    const questPageLink = quest.isAvailable ? `/games/${quest.name.toLowerCase()}/` : "#WhatKindOfQuester";

    return (
        <div className="QuestCard">
            <div className="QuestCardTitleContainer">
                <div style={{backgroundColor: quest.backgroundColor}} className="QuestCardTitle">
                    {ardiIcon(quest.iconColor, 20 , 26)}
                    {quest.name}
                </div>
            </div>
            <a href={questPageLink} style={{textDecoration: "none", color: "black"}}>
                <div style={{backgroundColor: quest.backgroundColor}} className="QuestCardContent">
                        <img className="QuestCardImage" src={quest.image} alt="Place Holder"/>
                    <div className="QuestCardContentDescription">
                        <h2>{quest.name}</h2>
                        {quest.description}
                        <h2>
                            {quest.isAvailable ? "" : "Coming Soon..."}
                        </h2>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default QuestCard