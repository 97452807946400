

export const testNews = [
    {
        "id": 1,
        "title": "Escape Room Craze Sweeps the Nation",
        "subtitle": "How immersive puzzles are captivating audiences",
        "image": "https://images.unsplash.com/photo-1567789390003-6a956045e378?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "text": "Escape rooms have become a popular pastime across the country, drawing in thrill-seekers and puzzle enthusiasts alike. These interactive experiences challenge participants to solve a series of puzzles within a set time limit in order to 'escape' from a themed room. With themes ranging from haunted houses to space stations, escape rooms offer a unique blend of entertainment and mental stimulation."
    },
    {
        "id": 2,
        "title": "Local Escape Room Wins National Award",
        "subtitle": "Innovative design and storytelling recognized",
        "image": "https://images.unsplash.com/photo-1496361769828-9e27149aa2e0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZXNjYXBlJTIwcm9vbXxlbnwwfDB8MHx8fDA%3D",
        "text": "An escape room in Portland has been awarded the prestigious 'Best Escape Room' title at the national Escape Room Awards. The room, known as 'The Enigma', impressed judges with its intricate puzzles, immersive storylines, and cutting-edge technology. Owners attribute their success to a commitment to creating a high-quality experience that captivates and challenges players."
    },
    {
        "id": 3,
        "title": "Escape Room Boom in Small Towns",
        "subtitle": "How escape rooms are revitalizing local economies",
        "image": "https://images.unsplash.com/photo-1435265796918-0e3d3e4af435?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "text": "Small towns across the country are experiencing an economic boost thanks to the rising popularity of escape rooms. These attractions are drawing visitors from surrounding areas, leading to increased revenue for local businesses such as restaurants and shops. Entrepreneurs are finding that escape rooms are a profitable venture that can thrive in communities of any size."
    },
    {
        "id": 4,
        "title": "Escape Room Fundraiser Breaks Records",
        "subtitle": "Community comes together to support local charity",
        "image": "https://images.unsplash.com/photo-1581143761812-6d1474cdb9d4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "text": "A recent escape room fundraiser in Chicago has broken records for the most money raised in a single event. The event, organized by a local charity, featured several themed escape rooms and drew participants from all over the city. The funds raised will go towards supporting community programs and initiatives. Organizers credit the event's success to the engaging nature of escape rooms and the strong support from the community."
    }
]
