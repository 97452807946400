import React, { useState } from 'react';

const ExpandableTextBox = ({ color, title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='ExpandableTextBox' style={{backgroundColor: color}}>
      <div
        onClick={toggleExpansion}
        style={{
          padding: '10px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{title}</span>
        <span>{isExpanded ? '-' : '+'}</span>
      </div>
      <div
        style={{
          maxHeight: isExpanded ? '100px' : '0',
          overflow: 'hidden',
          transition: 'max-height 0.5s ease',
          padding: isExpanded ? '10px' : '0 10px',
        }}
      >
        {isExpanded && <div>{content}</div>}
      </div>
    </div>
  );
};

export default ExpandableTextBox;