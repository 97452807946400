import React from "react";


function NewsCard({news, number}) {

    const direction = number % 2 !== 0 ? "row" : "row-reverse"

    return (
        <div id={news.id} style={{ display: "flex", flexDirection: direction}} className="NewsCard">
            <div className="NewsCardLeft" >
                <h1>{news.title}</h1>
                <h4>{news.subtitle}</h4>
                <img src={news.image_url} alt={news.title}/>
            </div>
            <div className="NewsCardRight">
                <p>{news.content}</p>
            </div>


        </div>
    );
}

export default NewsCard